$primary-font: "Barlow";
$secondary-font: "Material Icons";

// update color of UI team

$color-pallet-primary-00: #f7f8f9;
$color-pallet-primary-01: #eff1f3;
$color-pallet-primary-02: #e8eced;
$color-pallet-primary-03: #e0e3e6;
$color-pallet-primary-04: #c9cfd4;
$color-pallet-primary-05: #818f99;
$color-pallet-primary-05-1: rgba(129, 143, 153, 0.1);
$color-pallet-primary-06: #3b4957;
$color-pallet-primary-07: #2b3847;
$color-pallet-primary-08: #1a2430;
$color-pallet-primary-09: #141d27;
$color-pallet-primary-09-1: rgba(21, 30, 40, 0.1);
$color-pallet-primary-10: #111921;
$color-pallet-primary-11: #0e1117;
$color-pallet-primary-12: #51bac3;
$color-pallet-primary-13: #2a9c65;
$color-pallet-primary-14: #b89600;
$color-pallet-primary-15: #a50f22;
$color-pallet-primary-16: #bbccdd;
$color-pallet-primary-17: #162a3f;
$color-pallet-primary-18: #818899;
$color-pallet-primary-19: #3275bb;
$color-pallet-primary-20: #6f9ecd;
$color-loading: rgba(0, 0, 0, 0.4);
// $color-pallet-primary-22:
$color-pallet-primary-23: #29a5cb;
$color-pallet-primary-24: #88b73a;

$color-pallet-primary-00-alpha: rgba(247, 248, 249, 0.85);
$color-pallet-primary-01-alpha: hsla(0, 0%, 100%, 0.75);
$color-pallet-primary-02-alpha: rgba(232, 236, 237, 0.5215686274509804);
$color-pallet-primary-08-alpha: rgba(0, 0, 0, 0.75);
$color-pallet-primary-09-alpha: rgba(0, 0, 0, 0.55);
$color-pallet-primary-10-alpha: #1119211F;
$color-pallet-primary-10-alpha1: rgba(0, 0, 0, 0.5);
$color-pallet-primary-10-alpha2: rgba(17, 25, 33, 0.2);
$color-pallet-primary-10-alpha3: rgba(0, 0, 0, 0.75);
$color-pallet-primary-18-alpha: rgba(129, 136, 137, 0.2);

// Select Colors
$color-select-blue-light: #bbccdd;
$color-hover-blue-light: #e0e3e6;
$color-select-blue-dark: #1b334b;
$color-hover-blue-dark: #1a2430;

// Select Colors - Light
$light-color-select-blue-light: #1b334b;
$light-color-hover-blue-light: #1a2430;
$light-color-select-blue-dark: #e0e3e6;
$light-color-hover-blue-dark: #bbccdd;

// Indicator Colors
$--color-primary: #3275b8;
$--color-primary-lighten: rgba(50, 117, 184, 0.15);
$--color-success: #32b877;
$--color-warning: #d9b100;

$--color-error: #c71a2f;
$--colors-wellConstruction: #ed3127;
$--colors-hall: #e5181b;
$--color-urgent: #c8102e;
$--color-message: #dd7d80;
$--color-progress-line: #f87f14;
$--color-message-admin :#235281;

// Flag Colors
$--color-primary-points: $color-pallet-primary-01;
$--color-green: #32b877;
$--color-yellow: #d9b100;
$--color-red: #c21228;
$--color-blue: #6f9ecd;
$--color-grey: #c9cfd4;
$--color-orange: #f7a040;
$--color-peach: #df7a5a;
$--color-maroon: #995b5b;
$--color-brown: #874f11;
$--color-cyan: #49b2d3;
$--color-pink: #dd96b9;
$--color-navy: #6a85ce;
$--color-purple: #a36be6;
$--color-lime: #91a62b;

$--deep-primary: #3b82f6;



$light-color-pallet-primary-00: #111921; // duplicated
$light-color-pallet-primary-01: #111921; //
$light-color-pallet-primary-02: #141d27;
$light-color-pallet-primary-03: #2b3847; // duplicated
$light-color-pallet-primary-04: #111921; 
$light-color-pallet-primary-05: #2B3847;
$light-color-pallet-primary-06: #818f99;
$light-color-pallet-primary-07: #c9cfd4;
$light-color-pallet-primary-08: #E8ECED;
$light-color-pallet-primary-09: #eff1f3;
$light-color-pallet-primary-09-1: rgba(239, 241, 243, 0.1);
$light-color-pallet-primary-10: #eff1f3;
$light-color-pallet-primary-11: #f7f8f9;
$light-color-pallet-primary-19: #3275bb;
$light-color-pallet-primary-20: #6f9ecd;
$light-color-loading: rgba(0, 0, 0, 0.4);


// mixin that enables css variables in dark mode
@mixin darken() {
    --color-pallet-primary-00: #{$color-pallet-primary-00};
    --color-pallet-primary-01: #{$color-pallet-primary-01};
    --color-pallet-primary-02: #{$color-pallet-primary-02};
    --color-pallet-primary-03: #{$color-pallet-primary-03};
    --color-pallet-primary-04: #{$color-pallet-primary-04};
    --color-pallet-primary-05: #{$color-pallet-primary-05};
    --color-pallet-primary-06: #{$color-pallet-primary-06};
    --color-pallet-primary-07: #{$color-pallet-primary-07};
    --color-pallet-primary-08: #{$color-pallet-primary-08};
    --color-pallet-primary-09: #{$color-pallet-primary-09};
    --color-pallet-primary-09-1: #{$color-pallet-primary-09-1};
    --color-pallet-primary-10: #{$color-pallet-primary-10};
    --color-pallet-primary-11: #{$color-pallet-primary-11};
    --color-pallet-primary-18: #{$color-pallet-primary-05};
    --color-pallet-primary-19: #{$color-pallet-primary-19};
    --color-pallet-primary-20: #{$color-pallet-primary-20};
    --color-loading: #{$color-loading};
    --color-select-blue-light: #{$color-select-blue-light};
    --color-hover-blue-light: #{$color-hover-blue-light};
    --color-select-blue-dark: #{$color-select-blue-dark};
    --color-hover-blue-dark: #{$color-hover-blue-dark};
}

// mixin that enables css variables in light mode
@mixin lighten() {
    --color-pallet-primary-00: #{$light-color-pallet-primary-00};
    --color-pallet-primary-01: #{$light-color-pallet-primary-01};
    --color-pallet-primary-02: #{$light-color-pallet-primary-02};
    --color-pallet-primary-03: #{$light-color-pallet-primary-03};
    --color-pallet-primary-04: #{$light-color-pallet-primary-04};
    --color-pallet-primary-05: #{$light-color-pallet-primary-05};
    --color-pallet-primary-06: #{$light-color-pallet-primary-06};
    --color-pallet-primary-07: #{$light-color-pallet-primary-07};
    --color-pallet-primary-08: #{$light-color-pallet-primary-08};
    --color-pallet-primary-09: #{$light-color-pallet-primary-09};
    --color-pallet-primary-09-1: #{$light-color-pallet-primary-09-1};
    --color-pallet-primary-10: #{$light-color-pallet-primary-10};
    --color-pallet-primary-11: #{$light-color-pallet-primary-11};
    --color-pallet-primary-18: #{$light-color-pallet-primary-05};
    --color-pallet-primary-19: #{$light-color-pallet-primary-19};
    --color-pallet-primary-20: #{$light-color-pallet-primary-20};
    --color-loading: #{$light-color-loading};
    --color-select-blue-light: #{$light-color-select-blue-light};
    --color-hover-blue-light: #{$light-color-hover-blue-light};
    --color-select-blue-dark: #{$light-color-select-blue-dark};
    --color-hover-blue-dark: #{$light-color-hover-blue-dark};
}