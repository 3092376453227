html {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  border: none;
}

body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  border: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  outline: none;
}

input {
  line-height: normal;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

img, svg {
  vertical-align: unset;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
body ::-webkit-scrollbar-button {
  height: 12px;
}
body ::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}
body ::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}
body ::-webkit-scrollbar-thumb {
  background: var(--color-pallet-primary-06);
  border-radius: 8px;
}
body ::-webkit-scrollbar-thumb:hover {
  background: var(--color-pallet-primary-05);
}
body ::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.app {
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

::ng-deep .p-overlaypanel {
  user-select: none;
}

@font-face {
  font-family: Barlow;
  src: url(../../fonts/Barlow-Medium.ttf);
}
body {
  font-family: "Barlow", "Material Icons", sans-serif;
  font-size: 16px;
  line-height: 20px;
  height: 100%;
}
body ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: "Barlow", "Material Icons", sans-serif;
}
body ::-moz-placeholder { /* Firefox 19+ */
  font-family: "Barlow", "Material Icons", sans-serif;
}
body :-ms-input-placeholder { /* IE 10+ */
  font-family: "Barlow", "Material Icons", sans-serif;
}
body :-moz-placeholder { /* Firefox 18- */
  font-family: "Barlow", "Material Icons", sans-serif;
}

:host ::ng-deep .p-component {
  font-family: "Barlow", "Material Icons", sans-serif;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.short-label {
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.custom-svg-icon {
  position: relative;
  top: 1px;
}
.custom-svg-icon.hover:hover {
  cursor: pointer;
  color: var(--color-pallet-primary-02);
}

.custom-svg-icon-down {
  position: relative;
  top: 3px;
}

.light .addproject-icon,
.light .addrig-icon,
.light .wellbore-icon,
.light .addwell-icon,
.light .setting-icon,
.light .file-icon,
.light .export-icon,
.light .reup-icon,
.light .eye-icon,
.light .interval-icon,
.light .run-icon,
.light .edit-icon,
.light .restore-icon,
.light .activeStatus-icon,
.light .inactiveStatus-icon,
.light .check-icon,
.light .open-icon,
.light .cancel-icon {
  filter: brightness(0) saturate(100%) invert(25%) sepia(21%) saturate(562%) hue-rotate(169deg) brightness(96%) contrast(89%);
}

.c-icons {
  width: 1rem;
  height: 1rem;
}

.addproject-icon {
  background-image: url("../../../assets/images/icons/svg-image/project.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.addrig-icon {
  background-image: url("../../../assets/images/icons/svg-image/rig.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.wellbore-icon {
  background-image: url("../../../assets/images/icons/svg-image/wellbore.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.addwell-icon {
  background-image: url("../../../assets/images/icons/svg-image/well.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.setting-icon {
  background-image: url("../../../assets/images/icons/svg-image/setting.svg");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.file-icon {
  background-image: url("../../../assets/images/icons/svg-image/file.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.export-icon {
  background-image: url("../../../assets/images/icons/svg-image/export.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.reup-icon {
  background-image: url("../../../assets/images/icons/svg-image/reup.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.trash-bin-icon {
  background-image: url("../../../assets/images/icons/svg-image/trash-bin.svg");
  filter: brightness(0) saturate(100%) invert(14%) sepia(47%) saturate(5134%) hue-rotate(339deg) brightness(96%) contrast(104%);
  background-repeat: no-repeat;
  background-position: center center;
}

.eye-icon {
  background-image: url("../../../assets/images/icons/svg-image/eye.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.interval-icon {
  background-image: url("../../../assets/images/icons/svg-image/interval.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.run-icon {
  background-image: url("../../../assets/images/icons/svg-image/run.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.warning-icon {
  background-image: url("../../../assets/images/icons/svg-image/warning.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.edit-icon {
  background-image: url("../../../assets/images/icons/svg-image/edit.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.restore-icon {
  background-image: url("../../../assets/images/icons/svg-image/restore.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.activeStatus-icon {
  background-image: url("../../../assets/images/icons/svg-image/activeStatus.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.inactiveStatus-icon {
  background-image: url("../../../assets/images/icons/svg-image/inactiveStatus.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.check-icon {
  background-image: url("../../../assets/images/icons/svg-image/check.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.open-icon {
  background-image: url("../../../assets/images/icons/svg-image/open.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.nominate-icon {
  background-image: url("../../../assets/images/icons/svg-image/nominate_icon.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.cancel-icon {
  background-image: url("../../../assets/images/icons/svg-image/cancel.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-p-drop .p-dropdown {
  height: 32px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Barlow";
  border-radius: 4px;
  border: var(--color-pallet-primary-07) 1px solid;
  background-color: var(--color-pallet-primary-09);
}
.custom-p-drop .p-dropdown.p-disabled span {
  color: var(--color-pallet-primary-05);
  border: none;
}
.custom-p-drop .p-dropdown .p-dropdown-panel {
  width: inherit;
  background-color: var(--color-pallet-primary-09);
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  width: inherit;
  border-radius: 4px;
  border: 1px solid var(--color-pallet-primary-06);
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-header {
  margin: 0;
  padding: 0px 12px;
  height: 36px;
  background: var(--color-pallet-primary-10);
  border: 1px solid var(--color-pallet-primary-06);
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  display: flex;
  align-items: center;
  padding: 0px;
  height: 32px;
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items {
  font-family: "Barlow";
  padding: unset;
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  height: 32px;
  font-size: 14px;
  color: var(--color-pallet-primary-18);
  padding: 0px 12px;
  border-radius: 4px;
  align-items: center;
  display: flex;
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-disabled {
  color: var(--color-pallet-primary-06) !important;
  opacity: 1;
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-disabled .p-dropdown-label.p-inputtext {
  color: var(--color-pallet-primary-06);
  opacity: 1;
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-p-drop .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  height: 32px;
  color: var(--color-pallet-primary-05);
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0px;
  display: flex;
  align-items: center;
}
.custom-p-drop .p-dropdown .p-dropdown-trigger {
  background-color: var(--color-pallet-primary-09);
}
.custom-p-drop .p-dropdown .p-dropdown-label {
  font-family: "Barlow";
  color: var(--color-pallet-primary-04);
  font-size: 14px;
  font-weight: 500;
  border: unset !important;
  padding: 0px 12px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.custom-p-drop .p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--color-pallet-primary-05);
}
.custom-p-drop .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: unset;
}

.dropdown-wapper {
  width: 200px !important;
}
.dropdown-wapper span {
  color: var(--color-pallet-primary-04);
}

.custom-p-input.p-inputtext {
  font-family: "Barlow";
  color: var(--color-pallet-primary-03);
  background-color: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-07);
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 5px 12px;
}
.custom-p-input.p-inputtext:disabled {
  color: #3b4957;
}
.custom-p-input.p-inputtext:enabled:focus {
  border-color: #4d80ca !important;
  box-shadow: 0 0 0 0.02rem #2b3847 !important;
}
.custom-p-input.p-input-left-icon.p-inputtext {
  padding: 0px 32px 0px 12px;
}
.custom-p-input.error-border {
  border: 1px solid var(--color-error);
}

.custom-p-textarea.p-inputtext {
  font-family: "Barlow";
  color: var(--color-pallet-primary-03);
  background-color: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-07);
}
.custom-p-textarea:disabled {
  color: #3b4957;
}

.custom-p-multiselect .p-multiselect {
  width: 100% !important;
  height: 32px !important;
  border-radius: 4px;
  background-color: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-06);
}
.custom-p-multiselect .p-multiselect .p-inputtext {
  font-family: "Barlow";
  color: var(--color-pallet-primary-04);
  background-color: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-06);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.custom-p-multiselect .p-multiselect .p-multiselect-label {
  font-size: 14px;
  height: 32px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  color: var(--color-pallet-primary-01);
}
.custom-p-multiselect .p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--color-pallet-primary-04);
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-header {
  background: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-06);
  padding: 10px 12px;
  border-radius: 0px;
}
.custom-p-multiselect .p-multiselect .p-multiselect-filter-container .p-inputtext {
  height: 32px;
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel {
  width: 100%;
  background: var(--color-pallet-primary-09);
  z-index: 1098 !important;
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: var(--color-pallet-primary-09);
  color: var(--color-pallet-primary-02);
  font-size: 14px;
  border-radius: 4px;
  margin: 0;
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-02);
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
  padding: 6px 12px;
  color: var(--color-pallet-primary-02);
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items {
  padding: 0px;
}
.custom-p-multiselect .p-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 6px 12px;
}
.custom-p-multiselect .p-multiselect .p-multiselect-items-wrapper {
  border: 1px solid var(--color-pallet-primary-06);
  border-radius: 4px;
}
.custom-p-multiselect .p-multiselect .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-pallet-primary-01);
  border-color: var(--color-pallet-primary-01);
}
.custom-p-multiselect .p-multiselect .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: var(--color-pallet-primary-11);
  font-size: 11px;
}
.custom-p-multiselect .p-multiselect .p-checkbox .p-checkbox-box {
  width: 14px;
  height: 14px;
  border-radius: 0px;
  margin-top: 3px;
  background: transparent;
}
.custom-p-multiselect .p-multiselect .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--color-pallet-primary-01);
  border-color: var(--color-pallet-primary-01);
}
.custom-p-multiselect .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: unset;
}

.edit-form .smart-text-input {
  width: 100%;
}
.edit-form .smart-text-input .p-inputtext {
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-pallet-primary-04);
  background: transparent;
  border-color: var(--color-pallet-primary-06);
}
.edit-form .smart-text-input .p-autocomplete-panel {
  border: 1px solid var(--color-pallet-primary-06);
  background: var(--color-pallet-primary-09);
  border-radius: 4px;
  padding: 0px;
}
.edit-form .smart-text-input .p-autocomplete-panel .p-autocomplete-items {
  background: var(--color-pallet-primary-09);
  padding: 0px;
}
.edit-form .smart-text-input .result-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.edit-form .smart-text-input .add-new-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}
.edit-form .smart-text-input .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  color: var(--color-pallet-primary-02);
  padding: 10px;
}
.edit-form .smart-text-input .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: var(--color-pallet-primary-02);
  background: var(--color-pallet-primary-07);
}
.edit-form .smart-text-input .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: var(--color-pallet-primary-07);
}

.custom-p-tree .p-treeselect {
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  height: 32px;
  padding: 3px 0px 4px 10px;
  font-size: 14px;
  border: 1px solid var(--color-pallet-primary-06);
  width: 100% !important;
  background: var(--color-pallet-primary-09);
  border-radius: 4px;
}
.custom-p-tree .p-treeselect .p-treeselect-label {
  padding: unset;
  line-height: 24px;
}
.custom-p-tree .p-treeselect:not(.p-disabled).p-focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-p-tree .p-treeselect .p-treeselect-items-wrapper {
  background: var(--color-pallet-primary-09);
  border-radius: 4px;
  border: 1px solid var(--color-pallet-primary-06);
}
.custom-p-tree .p-treeselect:not(.p-disabled):hover {
  border-color: #3b82f6;
}
.custom-p-tree .p-treeselect-clearable .p-treeselect .p-treeselect-clear-icon {
  margin-right: 36px;
  right: 0;
}
.custom-p-tree .p-treeselect-clearable .p-treeselect .p-treeselect-trigger {
  padding-right: 20px;
  width: 0;
}
.custom-p-tree .p-tree {
  padding: 0px;
}
.custom-p-tree .p-tree .p-tree-wrapper .p-tree-container {
  overflow-x: hidden;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode {
  color: var(--color-pallet-primary-18);
  background: var(--color-pallet-primary-09);
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 2px 2px;
  font-size: 14px;
  position: relative;
  height: 32px;
  padding-left: 42px;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  color: var(--color-pallet-primary-04);
  margin-right: 0px;
  position: absolute;
  padding-right: 100%;
  left: -15px;
  padding-left: 28px;
  display: block;
  padding-bottom: 3px;
  margin-top: -2px;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler span {
  font-size: 12px;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-18);
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-18);
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode.parent > div:hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-18);
}
.custom-p-tree .p-tree .p-tree-container .p-treenode.parent > div > div.p-checkbox {
  display: none;
}
.custom-p-tree .p-tree .p-treenode-content .p-tree-toggler:enabled:hover {
  border-color: transparent !important;
  background: transparent !important;
}
.custom-p-tree .p-tree-container .p-treenode.lowestChild .p-treenode-content .p-tree-toggler {
  color: var(--color-pallet-primary-06);
  padding-right: 28px;
}
.custom-p-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  align-items: center;
}
.custom-p-tree .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: transparent;
}
.custom-p-tree .p-checkbox .p-checkbox-box {
  border: 2px solid var(--color-pallet-primary-02);
  background: transparent;
  width: 14px;
  height: 14px;
  color: var(--color-pallet-primary-10);
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.custom-p-tree .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-pallet-primary-02);
}
.custom-p-tree .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
  color: var(--color-pallet-primary-10);
  font-weight: bold;
}
.custom-p-tree .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px;
}
.custom-p-tree .p-treeselect-panel .p-treeselect-items-wrapper .p-tree .p-tree-empty-message {
  padding: 6px 12px;
  color: var(--color-pallet-primary-18);
  background: var(--color-pallet-primary-09);
}

.custom-p-tree.without-parent .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding-left: 12px;
}

.custom-multiple-level .p-treeselect {
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  height: 32px;
  padding: 3px 0px 4px 10px;
  font-size: 14px;
  border: 1px solid var(--color-pallet-primary-06);
  width: 100%;
  background: var(--color-pallet-primary-09);
  border-radius: 4px;
}
.custom-multiple-level .p-treeselect .p-treeselect-label {
  padding: unset;
  line-height: 24px;
  color: var(--color-pallet-primary-04);
}
.custom-multiple-level .p-treeselect .p-treeselect-label.p-placeholder {
  color: var(--color-pallet-primary-05);
}
.custom-multiple-level .p-treeselect:not(.p-disabled).p-focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-multiple-level .p-treeselect .p-treeselect-items-wrapper {
  background: var(--color-pallet-primary-09);
  border-radius: 4px;
  border: 1px solid var(--color-pallet-primary-06);
}
.custom-multiple-level .p-treeselect:not(.p-disabled):hover {
  border-color: #3b82f6;
}
.custom-multiple-level .p-tree {
  padding: 0px;
  background: transparent;
}
.custom-multiple-level .p-tree .p-tree-wrapper .p-tree-container {
  overflow-x: hidden;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode {
  color: var(--color-pallet-primary-18);
  background: var(--color-pallet-primary-09);
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 2px 2px;
  font-size: 14px;
  position: relative;
  height: 32px;
  padding-left: 42px;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: unset;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  color: var(--color-pallet-primary-04);
  margin-right: 0px;
  position: absolute;
  padding-right: 100%;
  left: -15px;
  padding-left: 28px;
  display: block;
  padding-bottom: 3px;
  margin-top: -2px;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler span {
  font-size: 12px;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode.parent > div:hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode.parent > div > div.p-checkbox {
  display: none;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode.single-choose .p-treenode-content {
  padding-left: 16px;
}
.custom-multiple-level .p-tree .p-tree-container .p-treenode.single-choose .p-treenode-content .p-checkbox {
  margin-right: 14px;
}
.custom-multiple-level .p-tree .p-treenode-content .p-tree-toggler:enabled:hover {
  color: inherit !important;
  border-color: unset !important;
  background: unset !important;
}
.custom-multiple-level .p-tree .p-treenode-children {
  padding-left: 0px;
}
.custom-multiple-level .p-tree .p-treenode-children {
  padding-left: 16px;
}
.custom-multiple-level .p-tree-container .p-treenode.lowestChild .p-treenode-content .p-tree-toggler {
  color: var(--color-pallet-primary-06);
  padding-right: 28px;
}
.custom-multiple-level .p-treeselect-panel .p-treeselect-items-wrapper .p-tree .p-tree-empty-message {
  padding: 6px 12px;
  color: #495057;
  background: transparent;
}
.custom-multiple-level .p-checkbox {
  height: unset;
  width: unset;
}
.custom-multiple-level .p-checkbox .p-checkbox-box {
  border: 2px solid var(--color-pallet-primary-02);
  background: transparent;
  width: 14px;
  height: 14px;
  color: var(--color-pallet-primary-10);
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.custom-multiple-level .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-pallet-primary-02);
}
.custom-multiple-level .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
  color: var(--color-pallet-primary-10);
  font-weight: bold;
  font-size: 10px;
}

.custom-p-button--primary .p-button {
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-03);
  color: var(--color-pallet-primary-07);
}
.custom-p-button--primary .p-button:hover {
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-02);
  color: var(--color-pallet-primary-07);
}
.custom-p-button--primary.small-button .p-button {
  width: 56px;
  padding: 0px;
}
.custom-p-button--secondary .p-button {
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-02);
}
.custom-p-button--secondary .p-button:hover {
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-06);
  color: var(--color-pallet-primary-02);
}
.custom-p-button--secondary.small-button .p-button {
  width: 56px;
  padding: 0px;
}
.custom-p-button--primary .p-button.p-disabled, .custom-p-button--secondary .p-button.p-disabled {
  opacity: 1;
  background: var(--color-pallet-primary-09);
  color: var(--color-pallet-primary-06);
  border: 1px solid var(--color-pallet-primary-09);
}
.custom-p-button .p-button {
  font-family: "Barlow";
  height: 32px;
  border-radius: 4px;
  min-width: 72px;
  padding: 0px;
}
.custom-p-button .p-button .p-button-label {
  font-size: 14px;
  padding: 0 12px;
}
.custom-p-button .p-button:focus {
  box-shadow: none;
}
.custom-p-button.custom-p-button--disable .p-button {
  cursor: default !important;
}

.step-button {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
.step-button.step-button--primary {
  background-color: var(--color-pallet-primary-03);
  color: var(--color-pallet-primary-07);
}
.step-button.step-button--primary:hover {
  cursor: pointer;
  background: var(--color-pallet-primary-02);
  color: var(--color-pallet-primary-07);
}
.step-button.step-button--primary.step-button--disable {
  background-color: var(--color-pallet-primary-03);
  color: var(--color-pallet-primary-07);
  opacity: 0.6;
}
.step-button.step-button--primary.step-button--disable:hover {
  cursor: default;
}
.step-button.step-button--secondary {
  background-color: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-02);
}
.step-button.step-button--secondary:hover {
  cursor: pointer;
  background: var(--color-pallet-primary-06);
  color: var(--color-pallet-primary-02);
}
.step-button.step-button--secondary.step-button--disable {
  background-color: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-02);
  opacity: 0.6;
}
.step-button.step-button--secondary.step-button--disable:hover {
  cursor: default;
}

.secondary-disabled .custom-p-button .p-button {
  font-family: "Barlow";
  height: 32px;
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-09);
  color: var(--color-pallet-primary-06);
}
.secondary-disabled .custom-p-button .p-button:hover {
  border-color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-09);
  color: var(--color-pallet-primary-06);
}

.custom-p-checkbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: transparent;
}
.custom-p-checkbox .p-checkbox .p-checkbox-box {
  border: 2px solid var(--color-pallet-primary-02);
  background: transparent;
  width: 14px;
  height: 14px;
  color: var(--color-pallet-primary-10);
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.custom-p-checkbox .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--color-pallet-primary-02) !important;
}
.custom-p-checkbox .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
  color: var(--color-pallet-primary-10);
  font-weight: bold;
}
.custom-p-checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px;
}
.custom-p-checkbox .p-checkbox {
  height: unset;
  width: unset;
}
.custom-p-checkbox .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem transparent;
}
.custom-p-checkbox.bold {
  font-size: 14px;
  font-weight: 500;
}
.custom-p-checkbox.bold .p-checkbox .p-checkbox-box {
  border: 3px solid var(--color-pallet-primary-02);
  width: 16px;
  height: 16px;
}

.inner-msg-error,
.inner-msg-warning {
  font-size: 12px;
  margin-top: 4px;
  padding: 0px;
  font-weight: 500;
}

.inner-msg-error span {
  color: #c71a2f;
}

.inner-msg-warning span {
  color: #d9b100;
}

.primary-color {
  color: #3275b8;
}
.primary-color:hover {
  color: var(--color-pallet-primary-20);
}

.form-label {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-pallet-primary-04);
}

.inner-description-text {
  margin-top: 6px;
  padding: 0px;
}
.inner-description-text span {
  color: var(--color-pallet-primary-06);
  font-size: 12px;
  font-weight: 500;
}

.label-disabled {
  color: var(--color-pallet-primary-05) !important;
}

.error-border {
  border: 1px solid #c71a2f !important;
}

.search-box {
  height: 32px;
  width: 320px;
}

.search-box.p-inputtext {
  font-family: "Barlow";
  color: var(--color-pallet-primary-04);
  background-color: var(--color-pallet-primary-09);
  border-radius: 4px;
  border: 1px solid var(--color-pallet-primary-06);
  font-size: 14px;
  font-weight: 500;
}
.search-box.p-inputtext:disabled {
  color: #3b4957;
  opacity: 1;
}

.custom-toaster .p-toast-top-right {
  right: -8px !important;
  top: 56px !important;
}
.custom-toaster .p-toast .p-toast-message,
.custom-toaster .p-toast .p-toast-message {
  background: transparent;
  border: none;
  color: #141d27;
  width: 375px;
}
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close .p-toast-icon-close-icon,
.custom-toaster .p-toast .p-toast-message .p-toast-message-icon .p-toast-icon-close-icon,
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close .p-toast-icon-close-icon,
.custom-toaster .p-toast .p-toast-message .p-toast-message-icon .p-toast-icon-close-icon {
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: bold;
}
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close:focus,
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset;
}
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close:hover,
.custom-toaster .p-toast .p-toast-message .p-toast-icon-close:hover {
  background: transparent;
}
.custom-toaster .p-toast {
  opacity: 1;
  z-index: 9500 !important;
  font-size: 14px;
  line-height: 20px;
}
.custom-toaster .p-toast .p-toast-message .p-toast-message-content {
  background: #32b877;
  padding: 16px 12px 16px 12px;
  justify-content: space-between;
}
.custom-toaster .p-toast .p-toast-message .p-toast-message-content .header-notification {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #141d27;
}
.custom-toaster .p-toast .p-toast-message .p-toast-message-content .header-notification .sumary-text {
  margin-bottom: 2px;
  margin-left: 12px;
  font-weight: 700;
  text-transform: capitalize;
}
.custom-toaster .p-toast .p-toast-message .p-toast-message-content .detail-notification .detail-text {
  font-weight: 500;
  margin-bottom: 12px;
  color: #3b4957;
  word-break: break-word;
}
.custom-toaster .p-toast .p-toast-message .p-toast-message-content .detail-notification .time-text {
  font-weight: 400;
  font-size: 12px;
  color: #3b4957;
}
.custom-toaster .p-toast .p-toast-message-success .p-toast-icon-close,
.custom-toaster .p-toast .p-toast-message-warning .p-toast-icon-close {
  color: #141d27;
}
.custom-toaster .p-toast .p-toast-message-info .p-toast-message-content {
  background: #1a2430;
}
.custom-toaster .p-toast .p-toast-message-info .p-toast-message-content .header-notification {
  color: #e8eced;
}
.custom-toaster .p-toast .p-toast-message-info .p-toast-message-content .detail-notification .detail-text {
  color: #c9cfd4;
}
.custom-toaster .p-toast .p-toast-message-info .p-toast-message-content .detail-notification .time-text {
  color: #c9cfd4;
}
.custom-toaster .p-toast .p-toast-message-info .p-toast-icon-close {
  color: #e8eced;
}
.custom-toaster .p-toast .p-toast-message-error .p-toast-message-content {
  background: #c71a2f;
}
.custom-toaster .p-toast .p-toast-message-error .p-toast-message-content .header-notification {
  color: #e8eced;
}
.custom-toaster .p-toast .p-toast-message-error .p-toast-message-content .detail-notification .detail-text {
  color: #c9cfd4;
}
.custom-toaster .p-toast .p-toast-message-error .p-toast-message-content .detail-notification .time-text {
  color: #c9cfd4;
}
.custom-toaster .p-toast .p-toast-message-error .p-toast-icon-close {
  color: #e8eced;
}
.custom-toaster .p-toast .p-toast-message-warning .p-toast-message-content {
  background: #d9b100;
}
.custom-toaster .p-toast .p-toast-message-warning .p-toast-message-content .header-notification {
  color: #141d27;
}
.custom-toaster .p-toast .p-toast-message-warning .p-toast-message-content .detail-notification .detail-text {
  color: #3b4957;
}
.custom-toaster .p-toast .p-toast-message-warning .p-toast-message-content .detail-notification .time-text {
  color: #3b4957;
}

.p-tooltip-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Barlow";
}

.p-tooltip-arrow {
  border-top-color: var(--color-pallet-primary-08) !important;
  border-bottom-color: var(--color-pallet-primary-08) !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5) !important;
}

.p-tooltip .p-tooltip-text {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
  background: var(--color-pallet-primary-08) !important;
  padding: 10px 12px !important;
  color: var(--color-pallet-primary-04) !important;
  text-align: center !important;
}

.tooltipText {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background: var(--color-pallet-primary-08);
  padding: 8px 12px !important;
  position: absolute;
  display: inline-block !important;
  visibility: hidden;
  top: calc(100% + 20px);
  font-weight: 400;
  font-size: 14px;
  color: var(--color-pallet-primary-04);
  z-index: 9999;
  text-align: center;
  width: 110%;
  white-space: wrap;
}
.tooltipText.tooltipText {
  width: auto;
  white-space: nowrap;
}
.tooltipText.title {
  left: 18px;
}
.tooltipText.button {
  left: -58%;
}
.tooltipText.button-right {
  right: -35px;
}
.tooltipText.button-right-default {
  right: -5px;
}
.tooltipText.button-right-default::before {
  left: calc(85% - 5px);
}
.tooltipText.visible {
  visibility: visible;
}
.tooltipText.tooltipText {
  width: auto;
  white-space: nowrap;
}

.tooltipText:before {
  content: "";
  display: block;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  background: var(--color-pallet-primary-08);
  position: absolute;
  top: -5px;
  left: calc(50% - 5px);
  box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.5);
}

.tooltipText.button-right:before {
  left: calc(83% - 5px);
}

.tooltipText.button-bottom {
  left: -50px;
}

.style-class-dialog.p-dialog {
  background: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-09);
  box-shadow: 0px 0px 4px rgba(129, 136, 137, 0.12);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: "Barlow";
}
.style-class-dialog.p-dialog .custom-close-icon {
  padding-right: 2px;
  cursor: pointer;
}
.style-class-dialog.p-dialog .p-dialog-header {
  font-size: 20px;
  font-weight: 600;
  padding: 16px 24px 10px 24px;
  color: var(--color-pallet-primary-02);
  background: var(--color-pallet-primary-09);
  align-items: flex-start;
  line-height: 28px;
}
.style-class-dialog.p-dialog .p-inputwrapper-focus .p-dropdown {
  border-color: #3b82f6 !important;
}
.style-class-dialog.p-dialog .p-dialog-content {
  font-size: 14px;
  font-weight: 500;
  padding: 0px 24px;
  color: var(--color-pallet-primary-04);
  background: var(--color-pallet-primary-09);
  overflow: unset;
}
.style-class-dialog.p-dialog .p-dialog-content .add-input-des {
  color: var(--color-pallet-primary-05);
  line-height: 18px;
}
.style-class-dialog.p-dialog .p-dialog-footer {
  padding: 16px 24px;
  background: var(--color-pallet-primary-09);
}
.style-class-dialog.p-dialog .p-dialog-header-icons {
  display: none;
}

.style-class-dialog-custom.p-dialog {
  font-family: "Barlow";
  border: 1px solid var(--color-pallet-primary-09);
  font-size: 20px;
  font-weight: 600;
}
.style-class-dialog-custom.p-dialog .p-dialog-header,
.style-class-dialog-custom.p-dialog .p-dialog-content {
  padding: 16px 24px;
  color: var(--color-pallet-primary-02);
  background: var(--color-pallet-primary-09);
  overflow: visible;
}
.style-class-dialog-custom.p-dialog .p-dialog-header-icon:enabled:hover {
  background: transparent;
}
.style-class-dialog-custom.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}
.style-class-dialog-custom.p-dialog .p-dialog-header-icon span {
  font-size: 16px;
  font-weight: 900;
}
.style-class-dialog-custom.p-dialog .p-dialog-footer {
  padding: 16px 24px;
  background: var(--color-pallet-primary-09);
}

:host::ng-deep .p-dialog .p-dialog-footer button {
  margin: 0px;
  min-width: 80px;
}

.p-calendar-clearable .custom-p-calendar.secondary-icon .p-calendar-clear-icon {
  margin-right: 30px;
}

.custom-p-calendar.p-calendar {
  display: flex !important;
}
.custom-p-calendar.p-calendar .p-inputtext {
  background-color: var(--color-pallet-primary-09);
  color: var(--color-pallet-primary-04);
  border: 1px solid var(--color-pallet-primary-07);
  height: 32px !important;
  padding-left: 32px !important;
  border-radius: 6px;
}
.custom-p-calendar.p-calendar .p-datepicker-trigger.p-button {
  width: auto;
  height: 32px;
  position: absolute;
  background: transparent;
  border: none;
  padding: 0px 0px 0px 10px;
  color: var(--color-pallet-primary-04);
}
.custom-p-calendar.p-calendar .p-datepicker {
  transform: scale(0.8);
  background-color: var(--color-pallet-primary-08);
  color: var(--color-pallet-primary-04);
}
.custom-p-calendar.p-calendar .p-datepicker .p-datepicker-header {
  color: var(--color-pallet-primary-03);
  background-color: var(--color-pallet-primary-09);
}
.custom-p-calendar.p-calendar .p-datepicker .p-datepicker-today span {
  background: var(--color-pallet-primary-06);
  color: var(--color-pallet-primary-04);
  border-color: transparent;
}
.custom-p-calendar.p-calendar .p-datepicker .p-timepicker button {
  border-radius: 50% !important;
}
.custom-p-calendar.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.custom-p-calendar.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: var(--color-pallet-primary-02);
  background: var(--color-select-blue-dark);
  border-color: transparent;
}
.custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus, .custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus, .custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus,
.custom-p-calendar.p-calendar .p-datepicker .p-link:focus,
.custom-p-calendar.p-calendar .p-datepicker table td > span:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}
.custom-p-calendar.p-calendar .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.custom-p-calendar.p-calendar .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--color-pallet-primary-04);
  background: var(--color-select-blue-dark);
  border-radius: 5px;
}
.custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover, .custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover, .custom-p-calendar.p-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #445d76;
}

.custom-p-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #445d76;
}

.custom-p-calendar .p-datepicker table td > span.p-highlight {
  font-weight: 800;
  color: var(--color-pallet-primary-07);
  background: var(--color-pallet-primary-03);
}

.custom-p-calendar.time-range-calendar.p-calendar {
  display: flex !important;
}
.custom-p-calendar.time-range-calendar.p-calendar .p-datepicker {
  background-color: var(--color-pallet-primary-08);
  color: var(--color-pallet-primary-04);
}
.custom-p-calendar.time-range-calendar.p-calendar .p-datepicker .p-datepicker-header {
  color: var(--color-pallet-primary-03);
  background-color: var(--color-pallet-primary-09);
}
.custom-p-calendar.time-range-calendar.p-calendar .p-datepicker .p-datepicker-today span {
  background: var(--color-pallet-primary-06);
  color: var(--color-pallet-primary-04);
  border-color: transparent;
}
.custom-p-calendar.time-range-calendar.p-calendar .p-datepicker-trigger.p-button {
  padding: unset;
}

.custom-p-calendar.time-range-calendar {
  height: 32px;
}
.custom-p-calendar.time-range-calendar .p-inputtext {
  padding-left: 10px !important;
  border-radius: 6px 0px 0px 6px;
  background: var(--ccolor-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-06);
  font-size: 14px;
}
.custom-p-calendar.time-range-calendar .p-datepicker-trigger.p-button {
  position: relative !important;
  background: var(--color-pallet-primary-04);
  color: var(--color-pallet-primary-09);
  border: 1px solid var(--color-pallet-primary-06);
  width: 37px;
}
.custom-p-calendar.time-range-calendar .p-datepicker-trigger.p-button .p-button-icon {
  background: var(--color-pallet-primary-04);
  font-weight: 600;
}
.custom-p-calendar.time-range-calendar.p-calendar .p-calendar-clear-icon {
  right: 48px;
}
.custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus,
.custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus,
.custom-p-calendar.time-range-calendar .p-link:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}
.custom-p-calendar.time-range-calendar.export .p-datepicker {
  transform: scale(1);
  left: -23px !important;
}
.custom-p-calendar.time-range-calendar .p-datepicker {
  transform: scale(1);
  left: 0px !important;
}
.custom-p-calendar.time-range-calendar .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight,
.custom-p-calendar.time-range-calendar .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--color-pallet-primary-04);
  background: #1b334b;
  border-radius: 5px;
}
.custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover, .custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover, .custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #445d76;
}
.custom-p-calendar.time-range-calendar .p-datepicker-title button {
  color: var(--color-pallet-primary-02) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.custom-p-calendar.time-range-calendar .p-datepicker-title button.p-datepicker-month {
  margin-right: 0px;
}
.custom-p-calendar.time-range-calendar .p-datepicker-header .p-datepicker-prev:enabled:hover,
.custom-p-calendar.time-range-calendar .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #e8eced;
  background: #1b334b;
  border-color: transparent;
}
.custom-p-calendar.time-range-calendar table td {
  padding: 2px;
}
.custom-p-calendar.time-range-calendar table td > span {
  width: 44px;
  height: 44px;
}
.custom-p-calendar.time-range-calendar table td > span.p-highlight {
  color: #e8eced;
  background: #1b334b;
  border-radius: 5px;
}
.custom-p-calendar.time-range-calendar table td > span:focus {
  box-shadow: none !important;
}
.custom-p-calendar.time-range-calendar .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  border-radius: 5px;
}
.custom-p-calendar.time-range-calendar .p-datepicker .p-datepicker-today span {
  border-radius: 5px;
}

:host::ng-deep .chip-wrapper {
  margin: 0px 10px;
}
:host::ng-deep .chip-wrapper .p-chip {
  height: 20px;
  margin-right: 6px;
  margin-top: 9px;
  border-radius: 4px;
  background: var(--color-pallet-primary-07);
}
:host::ng-deep .chip-wrapper .p-chip-text {
  max-width: 150px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-05);
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
:host::ng-deep .chip-wrapper .p-chip .pi-chip-remove-icon {
  color: var(--color-pallet-primary-05);
  font-size: 13px;
  line-height: 18px;
  margin-top: 2px;
}
:host::ng-deep .chip-wrapper .p-chip .pi-chip-remove-icon:hover {
  color: var(--color-pallet-primary-04);
}
:host::ng-deep .chip-wrapper .p-chip .pi-chip-remove-icon:focus {
  box-shadow: unset !important;
}

::ng-deep .p-dynamic-dialog.fullscreen-popup .p-dialog-content {
  width: 90vw;
  padding: 0px;
  margin-left: 40px;
  border-radius: 6px;
  user-select: none;
  background-color: var(--color-pallet-primary-10);
}
::ng-deep .p-dynamic-dialog.hide-dialog .p-dialog-content {
  background: transparent;
  margin: 0 auto;
}

.header-dialog {
  margin-left: 7px;
  margin-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.p-overlaypanel .p-overlaypanel-content {
  padding-top: 0px;
}

.p-menu.p-menu-overlay {
  background: var(--color-pallet-primary-09);
  margin-top: 10px;
  margin-left: 6px;
  width: 170px;
  border: 1px solid var(--color-pallet-primary-06);
}
.p-menu .p-menuitem-link {
  padding: 8px 12px;
  border: 1px solid var(--color-pallet-primary-09);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--color-pallet-primary-07);
  border: 1px solid var(--color-pallet-primary-07);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--color-pallet-primary-04);
}
.p-menu .p-menuitem-link:focus {
  box-shadow: unset;
  border: 1px solid #3275b8 !important;
}
.p-menu .p-submenu-header {
  display: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
}
.p-menu .p-menuitem.red-label .p-menuitem-link .p-menuitem-text {
  color: var(--color-pallet-primary-15);
}
.p-menu .p-menuitem.red-label .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: var(--color-pallet-primary-15);
}

.p-menu.p-menu-overlay {
  background: var(--color-pallet-primary-09);
  padding: 0px;
  width: 134px;
  border: 1px solid var(--color-pallet-primary-06) !important;
}
.p-menu.p-menu-overlay.menu-table-item.lower-item {
  margin-top: -16px;
}
.p-menu.p-menu-overlay .p-menuitem-link {
  padding: 10px 12px;
  border-radius: 6px;
}
.p-menu.p-menu-overlay.auto-item {
  margin-top: 0px;
  margin-left: 0px;
}

.extend-md-width-menu.p-menu.p-menu-overlay {
  width: 182px;
}

.md-width-menu.p-menu.p-menu-overlay {
  width: 170px;
}

.lg-width-menu.p-menu.p-menu-overlay {
  width: 240px;
}

.auto-width-menu.p-menu.p-menu-overlay {
  width: auto !important;
}

.inherit.p-menu.p-menu-overlay {
  margin-top: 0;
  margin-left: 0;
  width: 125px;
}

.sm-width-menu.p-menu.p-menu-overlay {
  padding: 0px;
  width: 118px;
}
.sm-width-menu.p-menu.p-menu-overlay ul li a {
  border-radius: 4px 4px 0 0;
  border: 1px solid transparent;
}
.sm-width-menu.p-menu.p-menu-overlay ul li:last-child a {
  border-radius: 0 0 4px 4px;
  border: 1px solid transparent;
}

.p-submenu-header {
  display: none;
}

.p-menu .p-menuitem.red-label .p-menuitem-link .p-menuitem-text {
  color: #c71a2f;
}
.p-menu .p-menuitem.red-label .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #c71a2f;
}
.p-menu .p-menuitem.yellow-label .p-menuitem-link .p-menuitem-text {
  color: #d9b100;
}
.p-menu .p-menuitem.yellow-label .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #d9b100;
}

.more-option-table {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
}
.more-option-table.active {
  border: 1px solid #3275b8;
}
.more-option-table.disabled {
  cursor: default;
  color: var(--color-pallet-primary-05);
}

.more-option-title {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
}
.more-option-title.active {
  border: 1px solid #3275b8;
}

.menu-table-item {
  margin-top: -16px;
}

.custom-table {
  word-break: break-word;
  margin-bottom: 12px;
}
.custom-table.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem transparent;
  outline: 0 none;
}
.custom-table.p-datatable .p-sortable-column:hover {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
}
.custom-table.p-datatable .p-datatable-thead > tr > th {
  background: var(--color-pallet-primary-07);
  color: var(--color-pallet-primary-05);
  font-size: 14px;
  font-weight: 500;
  border-color: transparent;
  border-right: 2px solid var(--color-pallet-primary-11);
  display: flex;
  justify-content: space-between;
}
.custom-table.p-datatable .p-datatable-thead > tr > th[aria-sort=ascending] .pi-sort-amount-up-alt:before {
  color: #3275b8;
}
.custom-table.p-datatable .p-datatable-thead > tr > th[aria-sort=descending] .pi-sort-amount-down:before {
  color: #3275b8;
}
.custom-table.p-datatable .p-datatable-thead > tr > th:last-child {
  border-right: none;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td {
  color: var(--color-pallet-primary-04);
  font-size: 14px;
  font-weight: 500;
  border-color: transparent;
  padding: 14px 12px;
  display: inline-block;
  width: 0px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature {
  display: flex;
  align-items: center;
  margin-top: 9px;
  gap: 4px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature.text-error {
  color: #c71a2f;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature.text-warning {
  color: #d9b100;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature .td-content {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-right: 6px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature .td-icon svg-icon {
  color: var(--color-pallet-primary-05);
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature span.text-error-icon {
  margin-top: 2px;
  color: #c71a2f;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-feature span.text-warning-icon {
  margin-top: 2px;
  color: #d9b100;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td:hover .tooltipText {
  visibility: visible;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-option {
  max-width: 50px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-default {
  min-width: 80px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-medium {
  min-width: 240px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-large {
  min-width: 320px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.td-long {
  min-width: 380px;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td .text-active {
  color: #32b877;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td .text-inactive {
  color: #c71a2f;
}
.custom-table.p-datatable .p-datatable-tbody > tr {
  background: var(--color-pallet-primary-10);
  cursor: pointer;
  height: 64px;
  position: relative;
}
.custom-table.p-datatable .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: var(--color-pallet-primary-08) !important;
}
.custom-table.p-datatable-striped .p-datatable-tbody tr:nth-child(even) {
  background: var(--color-pallet-primary-08);
}
.custom-table .p-datatable-wrapper {
  height: 100vh;
  max-height: calc(100vh - 292px);
}
.custom-table--fit .p-datatable-wrapper {
  height: auto;
  max-height: auto;
}
.custom-table.p-datatable .p-datatable-tbody > tr > td.primary-color {
  color: #3275b8;
}
.custom-table.p-datatable .p-datatable-thead > tr > th .pi-sort-amount-up-alt:before {
  font-family: "Material Icons";
  content: "\e5ce";
  color: var(--color-pallet-primary-05);
  font-weight: 900;
}
.custom-table.p-datatable .p-datatable-thead > tr > th .pi-sort-amount-down:before {
  font-family: "Material Icons";
  content: "\e5cf";
  color: var(--color-pallet-primary-05);
  font-weight: 900;
}
.custom-table.p-datatable .p-sortable-column th {
  display: flex;
  justify-content: space-between;
}
.custom-table.p-datatable .p-sortable-column.th-last {
  max-width: 50px;
  min-width: 50px;
}
.custom-table.p-datatable .p-sortable-column.th-document {
  min-width: 100px;
  max-width: 140px;
  justify-content: center;
}
.custom-table.p-datatable .p-sortable-column.th-document-sort {
  max-width: 94px;
  justify-content: center !important;
}
.custom-table.p-datatable .p-sortable-column.th-document-long {
  min-width: 380px;
}
.custom-table.p-datatable .p-sortable-column.th-document-large {
  min-width: 320px;
}
.custom-table.p-datatable .p-sortable-column.th-document-medium {
  min-width: 240px;
}
.custom-table.p-datatable .p-sortable-column.th-document-small {
  min-width: 140px;
}
.custom-table.p-datatable .p-sortable-column.th-document-role {
  display: flex;
  justify-content: space-between;
  min-width: 220px;
}
.custom-table.p-datatable .p-sortable-column.th-first {
  min-width: 318px;
}
.custom-table.p-datatable .p-sortable-column.th-first-left {
  max-width: 50px;
}
.custom-table.p-datatable .p-sortable-column.th-default {
  min-width: 80px;
}
.custom-table.p-datatable .p-sortable-column .th-content {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-right: 6px;
}
.custom-table.p-datatable .p-sortable-column .header-sort-icon {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.custom-table.p-datatable .p-sortable-column .header-sort-icon .pi-sort-amount-up-alt {
  margin-top: 2px;
}
.custom-table.p-datatable .p-sortable-column .header-sort-icon .pi-sort-amount-down {
  margin-top: -7px;
}

.p-overlay-badge .p-badge {
  font-size: 12px;
  height: 18px;
  line-height: 19px;
  padding: 0px 6px;
  margin-top: 1px;
  min-width: 0.5rem;
}

.p-skeleton {
  background-color: var(--color-pallet-primary-09);
}

.p-skeleton:after {
  background: linear-gradient(90deg, var(--color-pallet-primary-08), var(--color-pallet-primary-09-1), var(--color-pallet-primary-08));
}

.tabview-content__form {
  border-right: 1px solid var(--color-pallet-primary-11);
  overflow: auto;
  height: 100%;
}

.tabview-content .p-tabview-nav-container {
  margin-bottom: 6px;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav {
  background: transparent;
  border: none;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li.p-highlight a {
  color: var(--color-pallet-primary-02);
  background: transparent;
  border: none;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li.p-highlight a span {
  border-bottom: 1px solid #f87f14;
  padding-bottom: 6px;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li a {
  background: transparent;
  padding: 16px 16px 8px 0;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li a span {
  font-size: 12px;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  color: var(--color-pallet-primary-05);
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.tabview-content .p-tabview-nav-container .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent;
}

.tabview-content-wrapper .p-tabview {
  font-size: 14px;
}
.tabview-content-wrapper .p-tabview .tabview-content__form--INITIATED {
  font-size: 12px;
}
.tabview-content-wrapper .p-tabview .p-tabview-panels {
  background: transparent;
  color: var(--color-pallet-primary-05);
  padding: 0px;
  min-height: 260px;
}

.progressUpload {
  height: 4px;
  background: var(--color-pallet-primary-07);
}
.progressUpload .p-progressbar-value {
  background: #f87f14;
}

.in-unit::after {
  content: "in";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.ft-unit::after {
  content: "ft";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.mm-unit::after {
  content: "mm";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.m-unit::after {
  content: "m";
  position: absolute;
  top: 3px;
  right: 35px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-pallet-primary-04);
  line-height: 27.13px;
}

.non-icon.in-unit::after, .non-icon.mm-unit::after, .non-icon.ft-unit::after, .non-icon.m-unit::after {
  right: 12px;
}

.unit-disabled.in-unit::after, .unit-disabled.mm-unit::after, .unit-disabled.m-unit::after, .unit-disabled.ft-unit::after {
  color: #28343f;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #c71a2f;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #c71a2f !important;
}

.p-inputtext.ng-touched.ng-invalid {
  border-color: #c71a2f !important;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #c71a2f;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #c71a2f;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #c71a2f;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #c71a2f;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #c71a2f;
}

.content-progress-bar-notification {
  font-size: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.content-progress-bar-notification .loader {
  width: 30px;
  height: 30px;
  border: 4px solid var(--color-pallet-primary-07);
  border-bottom-color: #f87f14;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.content-progress-bar-notification .loader.scale-down {
  margin-top: 2px;
  scale: 0.5;
}
.content-progress-bar-notification .loader.scale-small-card {
  margin-top: 14px;
  scale: 0.7;
}
.content-progress-bar-notification .loader.scale-small-list {
  margin-top: -4px;
  scale: 1;
  width: inherit;
  height: inherit;
}