/* You can add global styles to this file, and also import other style files */
@import "../src/assets/styles/base/variable";

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

body.dark {
  @include darken();
}

body.light {
  @include lighten();
}



