@font-face {
  font-family: Barlow;
  src: url(../../fonts/Barlow-Medium.ttf);
}

body {
  font-family: $primary-font, $secondary-font, sans-serif;
  font-size: 16px;
  line-height: 20px;
  height: 100%;

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: $primary-font, $secondary-font, sans-serif;
  }
  ::-moz-placeholder { /* Firefox 19+ */
   font-family: $primary-font, $secondary-font, sans-serif;
  }
  :-ms-input-placeholder { /* IE 10+ */
   font-family: $primary-font, $secondary-font, sans-serif;
  }
  :-moz-placeholder { /* Firefox 18- */
   font-family: $primary-font, $secondary-font, sans-serif;
  }
}

:host ::ng-deep {
  .p-component {
    font-family: $primary-font, $secondary-font, sans-serif;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
