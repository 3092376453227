html {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  border: none;
}
body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  border: none;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}
input {
  line-height: normal;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}

img, svg {
  vertical-align: unset;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
