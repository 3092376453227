body {
  ::-webkit-scrollbar-button {
    height: 12px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    // display: none !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-pallet-primary-06);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-pallet-primary-05);
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent ;
  }
}

// Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.app {
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
}
.wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

::ng-deep {
  .p-overlaypanel {
    user-select: none;
  }
}